import { Image } from "./Image";

interface GalleryItem {
  title: string;
  description: string;
  image: string;
  link: string;
}

interface GalleryProps {
  data: {
    description: string;
    description2: string;
    items: GalleryItem[];
  }
}

export const Gallery = (props: GalleryProps) => {
  return (
    <div id='gallery' className='gallery text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <h3 className='sub-title'>{props.data ? props.data.description2 : 'Loading...'}</h3>
        </div>
        <div className='row'>
          <div className='gallery-items'>
            {props.data
              ? props.data.items.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} image={d.image} link={d.link} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
        <div className='cta'>
          <h3>{props.data ? props.data.description : 'Loading...'}</h3>
          <a href='https://www.etsy.com/shop/SirioItalianFactory' target={'_blank'} className='btn btn-default' rel="noreferrer">
            <img title={'etsy'} src={'img/etsy.webp'} alt={'etsy-logo'} className={'etsy-logo'} width={'100%'} height={'100%'}></img>
          </a>
        </div>
      </div>
    </div>
  )
}
