import * as React from 'react'

interface ContactProps {
  data: {
    name: string;
    email: string;
    message: string;
    address: string;
    phone: string;
    facebook: string;
    instagram: string;
    etsy: string;
    pinterest: string;
  };
}

export const Contact = (props: ContactProps) => {
  return (
    <div>
      <div id='contact' className='contact'>
        <div className='container'>
          <div className='row'>
            <div className='social'>
              <h1 className='follow-us'>Follow us on: </h1>
              <ul>
                <li>
                  <a href={props.data ? props.data.facebook : '/'} title={'facebook'} target={'_blank'} rel="noreferrer">
                    <i className='fa fa-facebook'></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.instagram : '/'} title={'instagram'} target={'_blank'} rel="noreferrer">
                    <i className='fa fa-instagram'></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.pinterest : '/'} title={'pinterest'} target={'_blank'} rel="noreferrer">
                    <i className='fa fa-pinterest'></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.etsy : '/'} title={'etsy'} target={'_blank'} rel="noreferrer">
                    <i className='fa fa-shopping-cart'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
