
import * as React from "react";
import JsonData from "./data/data.json";
import * as SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from './components/Navigation';
import { Header } from './components/Header';
import { About } from './components/About';
import { Gallery } from './components/Gallery';
import { Contact } from './components/Contact';
import { Parallax } from 'react-parallax';
import { Footer } from './components/Footer';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  // TODO: fix Data Schema
  const [landingPageData, setLandingPageData] = React.useState({} as any);
  React.useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const topics = React.useMemo(() => [
    'Seattle',
    'Italy',
    'Woodworking',
    'crafting',
    'christmas'
  ], []);

  const topic = topics[Math.floor(Math.random() * topics.length)];

  // if we want to add more pages, we can add them here
  const pages = React.useMemo(() => [], []);

  return (
    <div>
      <Parallax bgImage={`https://source.unsplash.com/random?${topic}`} strength={500} blur={10}>
        <Navigation pages={pages} />
        <Header data={landingPageData.Header} />
        <Gallery data={landingPageData.Gallery} />
        <About data={landingPageData.About} />
        <Contact data={landingPageData.Contact} />
        <Footer />
      </Parallax>
    </div >
  );
};

export default App;
