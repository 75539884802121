interface ImageProps {
  title: string;
  image: string;
  link: string;
}

export const Image = ({ title, image, link }: ImageProps) => {
  return (
    <div className='gallery-item'>
      <div className='hover-bg'>
        {' '}
        <a
          href={link}
          title={title}
          data-lightbox-gallery='gallery1'
          target='_blank' rel="noreferrer"
        >
          <div className='hover-text'>
            <h4>{title}</h4>
          </div>
          <img
            src={image}
            className='img-responsive'
            alt={title}
            width={'100%'}
            height={'100%'}
          />
        </a>
      </div>
    </div>
  )
}