interface NavigationProps {
  pages: {
    name: string;
    link: string;
  }[];
}

export const Navigation = (props: NavigationProps) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <a className='navbar-brand page-scroll' href='#page-top'>
            Sirio Italian Factory
          </a>
        </div>
        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {props.pages.map((page) => {
              return <li className="flag-wrapper">
                <a href={page.link} className='page-scroll'>
                  {page.name}
                </a>
              </li>
            })}
            <li className="flag-wrapper">
              <a href='#gallery' className='page-scroll'>
                Gallery
              </a>
            </li>
            <li className="flag-wrapper">
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li className="flag-wrapper">
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
