
import * as React from "react";
// import logo from '../assets/logo.svg';
import '../App.css';

interface HeaderProps {
  data: {
    title: string;
    paragraph: string;
  };
}

export const Header = (props: HeaderProps) => {
  return (
    <header className='header'>
      <Logo {...props} />
      <div className='intro'>
        <p>{props.data?.paragraph || 'Loading'}</p>
      </div>
    </header >
  )
}

function Logo(props: HeaderProps) {
  const images = React.useMemo(() => [
    'img/springbanner2.webp',
    'img/springbanner.webp',
  ], []);

  const xmasImg = images[Math.floor(Math.random() * images.length)];
  
  return <div className={'logo'}>
    {/* <img className={'logo-img'} src={logo} alt={props.data?.title} /> */}
    <img className={'logo-xmas'} src={xmasImg} alt={props.data?.title} />
  </div>
}