interface AboutProps {
  data: {
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4: string;
    paragraph5: string;
  };
}

export const About = (props: AboutProps) => {
  return (
    <div id='about' className="about">
      <div className="container">
        <div className="row">
          <div className="about-text">
            <h2>About Us</h2>
            {props.data?.paragraph1 ?
              <>
                <p>{props.data?.paragraph1}</p>
                <p>{props.data?.paragraph2}</p>
                <p>{props.data?.paragraph3}</p>
                <p>{props.data?.paragraph4}</p>
                <p>{props.data?.paragraph5}</p>
              </>
              : "loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
